.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 999;
    gap: 1.4rem;

}

.textWrapper {
    box-shadow: 0.2rem 0rem 1.5rem -0.2rem rgba(0, 0, 0, 1);
    border-radius: 1.6rem;
    padding: 0.4rem 1rem;
    background-color: rgb(255, 255, 255);
    position: relative;
    opacity: 1;

    transition: transform 0.3s ease;
}

.textWrapper::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    top: calc(50% - 0.5rem);
    right: -0.3rem;
    transform: rotate(45deg) !important;
    background-color: rgb(255, 255, 255);
}

.textWrapper:hover {
    transform: perspective(500px) translateZ(50px);
}

.text {
    font-size: 1.6rem;
    font-family: var(--bs-font-sans-serif);
    color: #232323;
}

.whatsappButton {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7rem;
    height: 7rem;
    background: linear-gradient(to bottom, #53c65d, #1DAC35);
    border-radius: 50%;
    box-shadow: -2px 2px 8px -2px #000000;

    transition: transform 0.3s ease;
}


.icon {
    width: 100%;
    height: 100%;
    padding: 8px;
}

.whatsappButton:hover {
    transform: perspective(500px) translateZ(50px);
}

@media (max-width: 767px) {
    .container {
        bottom: 1rem;
        right: 1rem;
    } 
}