.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 6rem;
}

.text {
    font-size: 1.6rem;
}