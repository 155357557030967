.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16rem 0;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
}

.content {
    width: 90%;
    max-width: 1120px;
    font-family: var(--bs-font-sans-serif);
}

.title {
    text-align: center;
    text-transform: uppercase;
    font-size: xx-large;
    margin-bottom: 8rem;
    color: #ffffff;
}

@media (max-width: 768px) {
    .container {
        padding: 8rem 0;
    }

    .title {
        font-size: medium;
        margin-bottom: 2rem;
    }
}