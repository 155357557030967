.container {
    display: flex;
    flex-direction: column;
}

.container::-webkit-scrollbar {
    width: 0px;
}

.city {}


.cityName {
    font-family: var(--bs-font-sans-serif);
    font-size: 1.6rem;
    color: rgba(35, 43, 148, 1);
    cursor: pointer;

    transition: transform 0.2s ease-in-out;
}

.cityName:hover {
    transform: perspective(100px) translateZ(20px);
}

@media (max-width: 600px) {
    .container {
        width: 90%;
        flex-direction: row;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: center;
        align-self: center;
        max-height: 25vh;
        overflow-y: scroll;
    }
}