.container {
    width: 70%;
    min-height: 100%;
    border-radius: 1rem;
    overflow: hidden;
    max-height: 100vw;
}

.content {
    width: 100%;
    height: 100%;
}


@media (max-width: 600px) {
    .container {
        width: 100%;
        max-height: calc(100% - 1px);
    }
    .content {
        height: 60vh;
    }
}