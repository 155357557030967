
.container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 2rem 0;
    gap: 2rem;
    
    font-family: var(--bs-font-sans-serif);
}

.title {
    margin: 0 0 0.6rem;
    font-size: 2rem;
}

.description {
    font-size: 1.4rem;
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 50%;
    color: rgba(35, 43, 148, 1);
}

@media (max-width: 767px) {
    .imageWrapper {
        width: 80%;
        max-width: 500px;
    }
}