.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16rem 0;
    background: #ffffff;
}

.content {
    width: 90%;
    max-width: 1120px;
    font-family: var(--bs-font-sans-serif);
}

.content h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: xx-large;
    margin-bottom: 8rem;
}

.partnesImagesWrapper {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-column-gap: 1rem;
    grid-row-gap:   2rem;
}

.partnerImage {
    width: 200px;
    align-self: center;
    justify-self: center;
}

@media (max-width: 768px) {
    .container {
        padding: 8rem 0;
    }
    
    .content h1 {
        font-size: large;
    }

    .partnesImagesWrapper {    
        margin-top: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}