.container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    position: absolute;
    z-index: 999;
    padding: 1rem;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1120px;
}

.logoWrapper {
    display: flex;
    align-items: center;
    width: 20rem;
    height: 4.6rem;
}

.logo {
    height: 100%;
}

.list {
    display: flex;
    gap: 0.6rem;
    list-style: none;
    padding: 0 2rem;
    margin: 0;
}

.listItem {
    border-bottom: 2px solid  rgba(4, 52, 109, 0);
    padding: 0 1.4rem 0.2rem;
    border-radius: 2.5rem;
    border: 2px solid white;

    transition: background-color 0.3s ease;
}

.listItem:hover {
    background-color: rgba(4, 52, 109, 1);
}

.link {
    font-size: 2.4rem;
    font-family: var(--bs-font-sans-serif);
    text-decoration: none;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
}

.navButton {
    display: none;
    position: absolute;
    top: 1rem;        
    right: 1rem;    
    width: 5rem;
    height: 5rem;
    z-index: 999;

}

.navButton>div {
    width: 100%;
    height: 0.8rem;
    background-color: rgb(255, 255, 255);
    margin: 0.6rem 0;
    border-radius: 1rem;

    transition: transform 0.3s, opacity 0.3s  ease-in-out;
}

.modalActive>div:nth-child(1) {
    transform: rotate(45deg) translate(8px, 8px);
}

.modalActive>div:nth-child(2) {
    opacity: 0;
}

.modalActive>div:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -7px);
}


@media (max-width: 600px) {
    .list {
        display: none;
    }

    .navButton {
        display: block;
    }
    
}