.footerCard {
    display: flex;
    height: 30rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 2rem;
    background-color: #FFFFFF;
    padding: 2rem;
    border-radius: 0.75rem;
    font-family: var(--bs-font-sans-serif);
    box-shadow: -0.5rem 0.5rem 2.5rem 0rem rgba(4, 11, 98, 0.2);
}



.footerCardContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerCardIcons {
    width: 6rem;
    height: 6rem;
}

.footerCard h2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0.75rem 0;
    color: rgb(4, 11, 98);
    font-family: var(--bs-font-sans-serif);
    text-transform: uppercase;
}

.footerCardText {
    font-size: 1.4rem;
    line-height: 2rem;
    text-align: center;
    overflow: hidden;
    height: calc(2rem * 4);
}

.footerCard>a {
    margin-top: 1rem;
    padding: 0.5rem 1rem;

    border: none;
    border-radius: 1.5rem;

    background-color: transparent;
    color: rgb(4, 11, 98);
    font-size: 1.4rem;
    font-weight: 700;
    font-family: var(--bs-font-sans-serif);
    text-decoration: none;


    transition: all 0.3s linear;
}

.footerCard>a:hover {
    background-color: rgb(4, 11, 98);
    color: #ffffff;
}