.container {
    display: flex;
    height: 22rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 20px 20px 50px;
    background-color: #FFFFFF;
    padding: 2rem;
    border-radius: 0.75rem;
    font-family: var(--bs-font-sans-serif);
    box-shadow: -5px 5px 25px 0px rgba(4, 11, 98, 0.2);
    transition: transform 0.4s ease-in-out;

}

.container:hover {
    transform: perspective(500px) translateZ(70px);
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
}


.title {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin: 0.75rem 0;
    color: rgb(4, 11, 98);
    font-family: var(--bs-font-sans-serif);
    transition: all 0.2s ease;
    text-transform: uppercase;
}

.description {
    font-size: 1.4rem;
    text-align: center;
    overflow: hidden;
    height: calc(2rem * 4);
    transition: all 0.2s ease;
}