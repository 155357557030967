.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 7rem 0 0;
    /* background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%); */

}

.navbarBackground {
    width: 100%;
    height: 7rem;
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
}

.content {
    width: 90%;
    min-height: 100%;
    max-width: 1120px;
    display: flex;
    padding: 2rem 0;
    justify-content: space-between;
    flex-grow: 1;
}

@media (max-width: 600px) {
    .content {
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;

    }
}