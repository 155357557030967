.container {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 1000px;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
    position: relative;
    z-index: 0;
    box-shadow: 0px 5px 15px -3px rgba(4, 11, 98);
    overflow: hidden;
}

.content {
    width: 90%;
    max-width: 1280px;
}

.backgroundSvg {
    position: absolute;
    width: 100%;
    min-width: 1440px;
    top: 50rem;
    left: 0;
    z-index: -1;
}

.backgroundNetwork {
    position: absolute;
    width: 100%;
    top: -50;
    left: 0;
    z-index: -1;
    opacity: 0.1;
}

.mainContent {
    display: flex;
    min-height: 60%;
    align-items: center;
    flex-wrap: wrap;
    padding: 14rem 0 0;
}

.textContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homeLogo {
    width: 80%;
    align-self: center;
}

.homeDescription {
    color: #FFFFFF;
    font-size: 2rem;
    padding-left: 2rem;
    margin-top: 1rem;
    font-family: var(--bs-font-sans-serif);
}

.homeButtons {
    display: flex;
    align-items: center;
    padding-left: 2rem;
    height: 4rem;
}

.homeButton {
    padding: 0.5rem 1.25rem 0.75rem;
    border-radius: 2rem;
    margin-right: 0.75rem;
    margin-top: 2rem;
    border: none;

    background: -webkit-linear-gradient(to right, rgb(252, 0, 255), rgb(0, 219, 222));
    background: linear-gradient(to right, rgb(252, 0, 255), rgb(0, 219, 222));
    color: #FFFFFF;
    text-decoration: none;

    font-family: var(--bs-font-sans-serif);
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 500;

    transition: all 0.3s ease;
}

.homeButton:first-child {
    background: transparent;
}

.homeButton:first-child:hover {
    font-size: 2rem;
}

.homeButton:last-child {
    font-size: 2rem;
}

.homeButton:last-child:hover {
    font-size: 2.5rem;
    border-radius: 2.5rem;
    animation: rgbButton 8s normal infinite;
}

.imageWrapper {
    position: relative;
    width: 50%;
}

.systems {
    width: 100%;
}

.circles {
    position: absolute;
    top: calc(50% - 40rem);
    right: -10rem;
    width: 80rem;
    z-index: -1;
}

.footer {
    display: flex;
    width: 100%;
    z-index: 999;
    margin: 12rem 0 4rem;
}

.footerContent {
    width: 100%;
}

@keyframes rgbButton {
    0% {
        background: rgb(252, 0, 255);
    }

    50% {
        background: rgb(0, 219, 222);
    }

    100% {
        background: rgb(252, 0, 255);
    }
}

@media (max-width: 767px) {
    .mainContent {
        flex-direction: column;
        gap: 3rem;
    }

    .textContent {
        width: 80%;
    }

    .footer {
        margin: 6rem 0 4rem;
    }

    .imageWrapper {
        width: 90%;
        margin-top: 6rem;
    }
}

@media (max-width: 400px) {
    .textContent {
        width: 96%;
    }
    .imageWrapper {
        width: 96%;
    }
}