.container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);

    transform: translateY(-100vh);

    transition: transform 0.2s ease-in-out;
}

.isOpen {
    transform: translateY(0vh);
}

.content {
    width: 90%;
    height: 80%;
}

.list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
    gap: 3rem;
}

.listItem {

}

.link {
    color: rgb(255, 255, 255);
    font-size: xx-large;
    text-decoration: none;
}