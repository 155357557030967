.container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    min-height: 90vh;
}

.content {
    width: 80%;
    max-width: 1120px;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.networkSvg {
    position: absolute;
    width: 100%;
    max-height: 50rem;
    opacity: 0.1;
}

.homeContent {
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    align-self: center;
}

.bgWrapper {
    position: absolute;
    min-width: 100%;
    bottom: -10px;
    max-height: 60vh;
    overflow: hidden;
}

.backgroundSvg {
    width: 100%;
    height: 100%;
}

.homeContent>div {
    width: 50%;
    z-index: 1;
}

.textContent {
    padding: 0 0 10rem;
}

.title {
    font-size: 5rem;
    color: #ffffff;
    text-align: center;
    margin: 8rem 0 2rem;
    text-transform: uppercase;
}

.description {
    font-size: 2rem;
    color: #ffffff;
    padding-right: 1rem;
    text-align: center;
}

.homeButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4rem 0 6rem;
    height: 4rem;
}

.ctaButton {
    padding: 1.5rem 3.25rem 1.75rem;
    border-radius: 4rem;


    background: -webkit-linear-gradient(to right, rgb(252, 0, 255), rgb(0, 219, 222));
    background: linear-gradient(to right, rgb(252, 0, 255), rgb(0, 219, 222));
    box-shadow: -3px 3px 12px -6px rgb(0, 0, 0);

    color: #FFFFFF;
    font-family: var(--bs-font-sans-serif);
    text-transform: uppercase;
    font-size: 2rem;
    font-weight: 500;
    text-decoration: none;

    transition: all 0.3s ease;
}

.ctaButton:hover {
    font-size: 2.6rem;
    animation: rgbButton 8s normal infinite;
}

.imgWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    max-width: 50%;
}

.mainImageType1 {
    width: 100%;
    max-height: 100%;
}

.mainImageType2 {
    max-height: 80%;
    max-width: 100%;
}

@media (max-width: 992px) {
    .homeContent {
        flex-direction: column;
        margin-top: 0rem !important;
        height: auto;
    }

    .content {
        margin-top: 0px;
    }

    .homeContent>div {
        margin-top: 8rem;
        width: 100%;
    }

    .title {
        font-size: 4rem;
    }

    .imgWrapper {
        display: none;
    }

    .footer {
        margin-top: 8rem;
    }


}

@media (max-width: 600px) {
    .networkSvg {
        width: 200%;
        text-align: center;
    }

    .textContent {
        padding-bottom: 4rem;
    }
}

@keyframes rgbButton {
    0% {
        background: rgb(252, 0, 255);
    }

    50% {
        background: rgb(25, 198, 201);
    }

    100% {
        background: rgb(252, 0, 255);
    }
}