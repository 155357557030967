.container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 7rem;
    height: 7rem;

    position: fixed;
    bottom: calc(1rem + 3rem + 7rem);
    right: 3rem;
    z-index: 999;

    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
    box-shadow: -2px 2px 8px -2px #000000;
    border-radius: 50%;
    cursor: pointer;

    transition: transform 0.7s ease;
}

.inactive {
    transform: translateX(12rem);
}

@media (min-width: 768px) {
    .container:hover {
        transition: transform 0.3s ease;

        transform: perspective(400px) translateZ(70px);
    }
}

@media (max-width: 767px) {
    .container {
        bottom: calc(1rem + 1rem + 7rem);
        right: 1rem;
    }
}