.employee {
    margin: 0;
    text-align: center;
}

.employeeImg {
    width: 20rem;
    height: 20rem;
    margin: 20px;
    border-radius: 50%;
    box-shadow: -2px 2px 25px -4px #000000;
}

.employeeName {
    margin: 1rem 0 0.25rem;
    color: #ffffff;
    font-family: var(--bs-font-sans-serif);
}

.employeePosition {
    color: #ffffff;
}

@media (max-width: 600px) {
    .employeeImg {
        width: 20rem;
        height: 20rem;
    }
    .employeeName {
        font-size: 1.6rem;
    }
}