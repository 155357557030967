.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
    padding: 16rem 0;
}

.container h1 {
    font-size: xx-large;
    color: #ffffff;
    margin-bottom: 6rem;
    text-transform: uppercase;
}

.content {
    width: 90%;
    max-width: 1120px;
    display: flex;
    color: #ffffff;
}

.features {
    width: 50%;
}

.feature {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 2rem 0;
    gap: 2rem;
    
    font-family: var(--bs-font-sans-serif);
}

.feature h2 {
    margin: 0 0 0.6rem;
    
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 1rem;
    border-radius: 50%;
    color: rgba(35, 43, 148, 1);
}

.imageWrapper {
    width: 50%;
    margin-left: 1rem;
    position: relative;
    display: flex;
    align-items: center;
}

.sectionImage {
    max-width: 100%;
    overflow: hidden;
    filter: grayscale(30%);
    border-radius: 0.5rem;
}

@media (max-width: 767px) {

    .container h1 {
        font-size: medium;
    }

    .content {
        flex-direction: column-reverse;
        align-items: center;

        width: 96%;
    }

    .features {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .imageWrapper {
        width: 80%;
        max-width: 500px;
    }
}