.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    padding: 7rem 0 0;
}

.navbarBackground {
    width: 100%;
    height: 7rem;
    position: absolute;
    top: 0;
    background: linear-gradient(90deg, rgba(35, 43, 148, 1) 0%, rgb(24, 79, 174) 50%, rgb(15, 99, 196) 100%);
}

.content {
    margin: 20px;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-size: 12px;
    margin: 5px 0px;
}

.containerTabRow {
    display: flex;
    flex-direction: column;
}

.tabRow {
    display: flex;
    flex-direction: row;
    width: 90%;
    font-size: 12px;
    margin: 0px 25px;
}

.bold {
    font-weight: 700;
    font-size: 13px;
}

@media (max-width: 600px) {
    .content {
        flex-direction: column;
        justify-content: flex-start;
        gap: 1rem;

    }
}