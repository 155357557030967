.container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 16rem 0;
    background-color: #ffffff;
    z-index: 998;
}

.container h1 {
    text-align: center;
    text-transform: uppercase;
    font-size: xx-large;
    margin-bottom: 8rem;
    font-family: var(--bs-font-sans-serif);

}

.clientContent {
    padding: 0 10px;
    display: flex;
    height: 100%;
    min-width: 300px;
    align-items: center;
}

.clientImgWrapper {
    width: 10rem;
    height: 10rem;
}

.clientImg {
    width: 10rem;
    height: 10rem;
}

.clientInfo {
    padding-left: 2rem;
}

.slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
    height: 100px;
    margin: auto;
    margin-bottom: 3rem;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.slideTrack {
    animation: scroll 25s linear infinite;
    display: flex;
    width: calc(300px * 14);
}

.slideTrack1 {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(300px * 14);
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-300px * 7))
    }
}

@media (max-width: 768px) {
    .clientContent {
        padding: 0 6px;
        min-width: 180px;
    }

    .clientImgWrapper {
        width: 6rem;
        height: 6rem;
    }

    .clientImg {
        width: 6rem;
        height: 6rem;
    }

    .slider {
        height: 60px;
    }

    .clientInfo {
        padding-left: 1rem;
        font-size: x-small;
    }

    .container h1 {
        font-size: large;
        margin: 0 0 2rem;
    }

}